import { useState } from "react";
import PublicGoogleSheetsParser from "./PublicGoogleSheetsParser";
import { saveAs } from "file-saver";

///.*\?state=(?<token>.*)/.exec(path)?.groups?.token;

function App() {
  const [optionsSheetUrl, setOptionsSheetUrl] = useState("");
  const [options, setOptions] = useState([]);
  const [storySheetUrl, setStorySheetUrl] = useState("");

  return (
    <div className={"p-10"}>
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Enter link to prompt construction spreadsheet:
      </h3>
      <form
        className="mt-5 sm:flex sm:items-center"
        onSubmit={(e) => {
          e.preventDefault();
          const sheetId =
            /docs.google.com\/spreadsheets\/d\/(?<sheetId>.*)\//.exec(
              optionsSheetUrl,
            ).groups?.sheetId;

          const parser = new PublicGoogleSheetsParser(sheetId);
          parser.parse().then((data) => {
            setOptions(
              data.columns
                .filter((column) => column !== "Scene Description")
                .map((column, id) => ({
                  prefix:
                    id <
                    data.columns.findIndex((c) => c === "Scene Description"),
                  name: column,
                  id,
                  options: data.rows
                    .map((row, id) =>
                      row[column]
                        ? { label: row[column], id, selected: false }
                        : null,
                    )
                    .filter((row) => row),
                })),
            );
          });
        }}
      >
        <div className="w-full sm:max-w-xs">
          <input
            onChange={(e) => setOptionsSheetUrl(e.currentTarget.value)}
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <button
          type="submit"
          onSubmit={() => null}
          className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
        >
          Submit
        </button>
      </form>
      <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-10 w-full pt-16">
        {options.map((category) => (
          <div className={"space-y-3"} key={category.id}>
            <div className={"text-base font-semibold leading-6 text-gray-900"}>
              {category.name}
            </div>
            {category.options.map((option) => (
              <div className="relative flex items-start" key={option.id}>
                <div className="flex h-6 items-center">
                  <input
                    value={option.selected}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={() => {
                      setOptions((prev) => {
                        return prev.map((column) => {
                          if (column.id === category.id) {
                            return {
                              ...column,
                              options: column.options.map((prevOp) => {
                                if (prevOp.id === option.id) {
                                  return {
                                    ...prevOp,
                                    selected: !prevOp.selected,
                                  };
                                }
                                return prevOp;
                              }),
                            };
                          }
                          return column;
                        });
                      });
                    }}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-900"
                  >
                    {option.label}
                  </label>{" "}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {options.length > 0 && (
        <div className={"mt-16 pb-24"}>
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Enter the link to story spreadsheet:
          </h3>
          <form
            className="mt-5 sm:flex sm:items-center"
            onSubmit={(e) => {
              e.preventDefault();
              const sheetId =
                /docs.google.com\/spreadsheets\/d\/(?<sheetId>.*)\//.exec(
                  storySheetUrl,
                ).groups?.sheetId;

              const parser = new PublicGoogleSheetsParser(sheetId);
              parser.parse().then((data) => {
                const scenes = data.rows.map(
                  (row) => row["Tokens for AI Image Generators"],
                );

                const prefix = options
                  .filter((category) => category.prefix)
                  .filter(
                    (category) =>
                      category.options.filter((option) => option.selected)
                        .length,
                  )
                  .map((category) => {
                    const selected = category.options.filter(
                      (option) => option.selected,
                    );

                    if (selected.length === 1) {
                      return selected[0].label;
                    }

                    return (
                      "{" +
                      selected.map((option) => option.label).join(", ") +
                      "}"
                    );
                  })
                  .join(", ");

                const suffix = options
                  .filter((option) => !option.prefix)
                  .filter(
                    (category) =>
                      category.options.filter((option) => option.selected)
                        .length,
                  )
                  .map((category) => {
                    const selected = category.options.filter(
                      (option) => option.selected,
                    );

                    if (selected.length === 1) {
                      return selected[0].label;
                    }

                    return (
                      "{" +
                      selected.map((option) => option.label).join(", ") +
                      "}"
                    );
                  })
                  .join(", ");

                const prompts = scenes.map(
                  (scene) =>
                    (prefix ? prefix + ", " : "") +
                    scene +
                    (suffix ? ", " + suffix : ""),
                );

                const s = prompts.join("\n");

                const blob = new Blob([s], { type: "text/plain" });
                saveAs(blob, "prompts.txt");
              });
            }}
          >
            <div className="w-full sm:max-w-xs">
              <input
                onChange={(e) => setStorySheetUrl(e.currentTarget.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <button
              type="submit"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;
